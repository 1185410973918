import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import FoundationIcon from "@mui/icons-material/Foundation";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { AppContext, ChainId, gasEstimationForAll, seeder } from "../utils";
import { useStakingContract, useTokenContract } from "../ConnectivityAss/hooks";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loader from "./Loading/Loading";
import { stakingAddress } from "../ConnectivityAss/environment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Home({ chainId, setOpen }) {
  const { account, connect, disconnect, signer } = useContext(AppContext);
  let StakingContract = useStakingContract(signer);
  let TokenContract = useTokenContract(signer);
  const [loading, setloading] = useState(false);
  const [EnterAmount, setEnterAmount] = useState("");
  const [refAddress, setRefrelAddress] = useState("");
  const [decimals, setdecimals] = useState("");
  const [WalletBalance, setWalletBalance] = useState("0");
  const [ContractBalance, setContractBalance] = useState("0");
  const [doubleEarning, setdoubleEarning] = useState("0");
  const [reffral_rewards, setreffral_rewards] = useState("0");
  const [admin, setAdmin] = useState("");
  // users
  const [userGetTotalRef1, setuserGetTotalRef1] = useState("0");
  const [userGetTotalRef2, setuserGetTotalRef2] = useState("0");
  const [userGetTotalRef3, setuserGetTotalRef3] = useState("0");
  const [userGetTotalRef4, setuserGetTotalRef4] = useState("0");
  const [userGetTotalRef5, setuserGetTotalRef5] = useState("0");

  // rewards
  const [getreward1, setgetreward1] = useState("0");
  const [getreward2, setgetreward2] = useState("0");
  const [getreward3, setgetreward3] = useState("0");
  const [getreward4, setgetreward4] = useState("0");
  const [getreward5, setgetreward5] = useState("0");
  // your stake
  const [YourStake, setYourStake] = useState("0");

  // handel expand
  const [expanded, setExpanded] = React.useState("1");
  // handel tree arrays
  // const [userDirects, setuserDirects] = useState([]);
  const [deposit, setdeposit] = useState([]);
  // totaldeposited'
  const [totaldeposited, settotaldeposited] = useState("");
  // total withdrawn
  const [totalWithDraw, settotalWithDraw] = useState("");

  // user stake and amount
  const [residualIncome, setresidualIncome] = useState("");
  const [residualWithdrawn, setresidualWithdrawn] = useState("");
  // get user withdraw
  const [getUserWithDraw, setgetUserWithDraw] = useState("");
  const [userDirects, setuserDirects] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (window.location.href.includes("?ref=")) {
      let getAddress = window.location.href.split("?ref=")[1];
      let final = getAddress.slice(0, 42);

      setRefrelAddress(final.toString());
      localStorage.setItem("BUSDSTACKER", final);
    }
  }, []);

  const getIntervalVal = async () => {
    let doubleEarning = await StakingContract.getUserDividendsWithdrawable(
      account
    );
    setdoubleEarning(formatUnits(doubleEarning.toString()));
  };

  useEffect(() => {
    setInterval(() => {
      if (account) {
        getIntervalVal();
      }
    }, 1000);
  }, [account]);

  const init = async () => {
    try {
      setloading(true);
      let admin = await StakingContract.owner();
      setAdmin(admin.toString());

      let decimal = await TokenContract.decimals();
      setdecimals(decimal.toString());
      let totalWithDraw = await StakingContract.getUserTotalWithdrawn(account);
      settotalWithDraw(formatUnits(totalWithDraw.toString()));
      let walletBalance = await TokenContract.balanceOf(account);
      let ContractBalance = await TokenContract.balanceOf(stakingAddress);
      setContractBalance(ContractBalance);
      setWalletBalance(walletBalance.toString());
      let yourStake = await StakingContract.getUserTotalDeposits(account);
      setYourStake(formatUnits(yourStake.toString()));
      let {
        levelbonus,
        levelbonuswithdrawn,
        additionalincome,
        additionalincomewithdrawn,
      } = await StakingContract.users(account);
      levelbonus = +formatUnits(levelbonus) - +formatUnits(levelbonuswithdrawn);
      additionalincome = parseFloat(
        +formatUnits(additionalincome) - +formatUnits(additionalincomewithdrawn)
      ).toFixed(0);
      console.log(additionalincome, "additionalincome");
      setreffral_rewards(levelbonus.toFixed(2));
      setresidualIncome(additionalincome);
      setresidualWithdrawn(formatUnits(additionalincomewithdrawn.toString()));
      let { level1, level2, level3, level4, level5 } =
        await StakingContract.getUserDownlineCount(account);
      setuserGetTotalRef1(level1.toString());
      setuserGetTotalRef2(level2.toString());
      setuserGetTotalRef3(level3.toString());
      setuserGetTotalRef4(level4.toString());
      setuserGetTotalRef5(level5.toString());

      let users = await StakingContract.getUserLevelIncome(account);

      setgetreward1(formatUnits(users[0].toString()));
      setgetreward2(formatUnits(users[1].toString()));
      setgetreward3(formatUnits(users[2].toString()));
      setgetreward4(formatUnits(users[3].toString()));
      setgetreward5(formatUnits(users[4].toString()));

      // let getUsertree = await StakingContract.getusertree(account);
      // console.log("getUsertree", getUsertree[0].toString());
      // setgetUsertreeCount(getUsertree[0].toString());
      // setgetUsertreeStake(getUsertree[1].toString());

      setgetUserWithDraw(
        formatUnits(levelbonuswithdrawn.toString(), decimal.toString())
      );
      let directs = await StakingContract.getuserDirects(account);
      console.log(directs, "directs");
      setuserDirects(directs?.slice(-10));
      console.log();
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("error init", error);
    }
  };

  const Deposit = async () => {
    if (!account) {
      toast.error("Please Connect Wallet");
    } else if (EnterAmount === "") {
      toast.error("Please Enter Amount");
    } else if (+EnterAmount === 0) {
      toast.error("Please Enter Amount Greater then Zero");
    } else if (+EnterAmount < 100) {
      toast.error("You can deposite minimum 100 BUSD.");
    } else {
      try {
        setloading(true);
        let allowance = await TokenContract.allowance(account, stakingAddress);
        let walletBalance = await TokenContract.balanceOf(account);
        console.log("allowance", allowance.toString());
        console.log("walletBalance", walletBalance.toString());
        let ref = refAddress ? refAddress : admin;
        console.log("ref", ref);
        if (+allowance < +walletBalance) {
          let fn = TokenContract.estimateGas.approve;
          let params = [stakingAddress, walletBalance];
          let tokenApprove = await TokenContract.approve(...params, {
            gasLimit: gasEstimationForAll(account, fn, params),
          });
          await tokenApprove.wait();
        }
        let fn = StakingContract.estimateGas.invest;
        let params = [
          ref,
          parseUnits(EnterAmount.toString(), decimals.toString()),
        ];
        let tx = await StakingContract.invest(...params, {
          gasLimit: await gasEstimationForAll(account, fn, params),
        });
        if (refAddress) {
          localStorage.removeItem("BUSDSTACKER");
          setRefrelAddress("");
        }

        await tx.wait();
        setloading(false);
        init();
        getIntervalVal();
        getUserDepositInfoRender();
        toast.success("Transaction Confirmed");
      } catch (error) {
        console.log("err", error);

        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message.slice(0, 74));
        }
        setloading(false);
      }
    }
  };

  const Withdraw = async () => {
    if (!account) {
      toast.error("Please Connect Wallet");
    } else {
      try {
        setloading(true);
        let fn = StakingContract.estimateGas.withdraw;
        let params = [];
        let tx = await StakingContract.withdraw({
          gasLimit: gasEstimationForAll(account, fn, params),
        });
        await tx.wait();
        setloading(false);
        init();
        getIntervalVal();
        getUserDepositInfoRender();
        toast.success("Transaction Confirmed");
      } catch (error) {
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message);
        }
        setloading(false);
      }
    }
  };

  const renivest = async () => {
    if (!account) {
      toast.error("Please Connect Wallet");
    } else {
      try {
        setloading(true);
        let tx = await StakingContract.ReinvestSTake();
        await tx.wait();
        setloading(false);
        init();
        getIntervalVal();
        getUserDepositInfoRender();
        toast.success("Transaction Confirmed");
      } catch (error) {
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message);
        }
        setloading(false);
      }
    }
  };

  const withdrawrefrewards = async () => {
    if (!account) {
      toast.error("Please Connect Wallet");
    } else if (+reffral_rewards < 100) {
      toast.error("You can Withdraw minimum 100 BUSD.");
    } else {
      try {
        setloading(true);
        let fn = StakingContract.estimateGas.withdrawbonus;
        let params = [];
        let tx = await StakingContract.withdrawbonus({
          gasLimit: gasEstimationForAll(account, fn, params),
        });
        await tx.wait();
        init();
        getIntervalVal();
        getUserDepositInfoRender();
        setloading(false);
      } catch (error) {
        toast.error("Error! No Funds available for Withdraw");
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message);
        }
        setloading(false);
      }
    }
  };

  const getUserDepositInfoRender = async () => {
    try {
      let arr = [];
      let getUserTotalDeposits = await StakingContract.getUserAmountOfDeposits(
        account
      );
      console.log("getUserTotalDeposits", getUserTotalDeposits.toString());
      for (let index = 0; index < +getUserTotalDeposits; index++) {
        let tx = await StakingContract.getUserDepositInfo(account, index);

        let obj = {
          amount: formatUnits(tx[0].toString()),
          withdrawn: formatUnits(tx[1].toString()),
          startime: tx[2].toString(),
        };

        arr.push(obj);
      }
      setdeposit(arr);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (account) {
      init();
      getUserDepositInfoRender();
    }
  }, [account]);
  // const initHandelTotalDepoited = async () => {
  //   try {
  //     let sum = 0;
  //     for (let index = 0; index < userDirects.length; index++) {
  //       // console.log("new items", userDirects[index]);
  //       let user = await StakingContract.users(userDirects[index]);
  //       // console.log("userDirects ==>", user[12].toString());
  //       sum += +user[12];
  //     }
  //     // console.log("sum", sum);
  //     settotaldeposited(sum);
  //   } catch (error) {
  //     console.log("error total", error);
  //   }
  // };
  // useEffect(() => {
  //   // console.log("userDirects.length", userDirects.length);
  //   if (account && userDirects.length > 0) {
  //     initHandelTotalDepoited();
  //   }
  // }, [account, userDirects]);
  return (
    <>
      <Box position="relative" pt={20} pb={8}>
        <Loader loading={loading} />
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={8} md={6} zIndex={1}>
              <Box
                width="100%"
                pt={4}
                pb={4}
                pl={2}
                pr={2}
                borderRadius="10px"
                style={{
                  display: "flex",
                  boxShadow: "#BC9F4E 0px 5px 15px",
                  justifyContent: "center",
                  flexDirection: "column",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
                mt={2}
                mb={5}
              >
                <Box fontSize="23px">
                  Welcome To 100k Passive Income Smart Contract, A Decentralized
                  Self-Sustaining Financial Sharing Network. The contract is
                  designed to pay members TWO Streams Of Passive Income With The
                  Ultimate Goal To Get YOUR Passive Income To Over $100,000.00
                  Per Year!
                </Box>
              </Box>

              {/* Staker Dashboard start */}
              <Box
                width="100%"
                pt={5}
                pb={5}
                borderRadius="10px"
                style={{
                  display: "flex",
                  boxShadow: "#BC9F4E 0px 5px 15px",
                  justifyContent: "center",
                  flexDirection: "column",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
                mt={2}
              >
                <Box
                  zIndex={1}
                  bgcolor="#1F1E1D"
                  width="95%"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#28A07F"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  mb={3}
                >
                  <Box display={"flex"} m={1}>
                    <FoundationIcon />{" "}
                  </Box>
                  Financial Dashboard
                </Box>
                <Box
                  m={2}
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Contract</Box>
                  <Box>
                    {ContractBalance
                      ? Number(formatUnits(ContractBalance.toString())).toFixed(
                          2
                        )
                      : 0}{" "}
                    BUSD
                  </Box>
                </Box>
                <Box
                  m={2}
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Wallet</Box>
                  <Box>
                    {WalletBalance
                      ? Number(formatUnits(WalletBalance.toString())).toFixed(2)
                      : 0}{" "}
                    BUSD
                  </Box>
                </Box>
                <Box
                  m={2}
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Your Total Staked</Box>
                  <Box>
                    {YourStake ? Number(YourStake).toFixed(2) : 0} BUSD
                    {/* {doubleEarning ? Number(doubleEarning).toFixed(2) : 0} BUSD */}
                  </Box>
                </Box>
                <Box
                  m={2}
                  p={2}
                  zIndex={1}
                  style={{
                    cursor: "pointer",
                  }}
                  width="95%"
                  height="45px"
                  fontWeight="small"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  alignItems="center"
                >
                  <input
                    type="text"
                    placeholder="Type BUSD Amount to Stake"
                    style={{
                      background: "transparent",
                      width: "100%",
                      height: "50px",
                      border: "1px solid white",
                      color: "white",
                      borderRadius: 10,
                    }}
                    onChange={(e) => setEnterAmount(e.target.value)}
                  />
                </Box>
                <Box
                  mt={2}
                  zIndex={1}
                  style={{
                    cursor: "pointer",
                  }}
                  bgcolor="#28A07F"
                  width="90%"
                  alignSelf="center"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => Deposit()}
                >
                  Stake Your BUSD
                </Box>
                <Box
                  m={2}
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Daily Reward Available :</Box>
                  <Box>
                    {doubleEarning ? Number(doubleEarning).toFixed(2) : 0} BUSD
                  </Box>
                </Box>
                {/* <Box
                  mt={2}
                  zIndex={1}
                  style={{
                    cursor: "pointer",
                  }}
                  bgcolor="#28A07F"
                  width="90%"
                  alignSelf="center"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => renivest()}
                >
                  {" "}
                  Compound Rewards
                </Box> */}
                <Box
                  mt={2}
                  zIndex={1}
                  style={{
                    cursor: "pointer",
                  }}
                  bgcolor="#28A07F"
                  width="90%"
                  alignSelf="center"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => Withdraw()}
                >
                  Withdraw Daily Rewards
                </Box>
                <Box m={2} p={2} fontSize="20px">
                  Hedge your networth against inflation, Stake BUSD and get
                  0.714% daily passive rewards and 120% gross ROI.
                </Box>
                <a
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  target="_blank"
                  href={`https://bscscan.com/address/${stakingAddress}#code`}
                >
                  <Box
                    mt={2}
                    zIndex={1}
                    style={{
                      cursor: "pointer",
                    }}
                    bgcolor="#28A07F"
                    width="90%"
                    alignSelf="center"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Verified Contract
                  </Box>
                </a>
                {/* <a
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  // target="_blank"
                  // href="https://t.me/busdstaker"
                >
                  <Box
                    mt={2}
                    zIndex={1}
                    style={{
                      cursor: "pointer",
                    }}
                    bgcolor="#28A07F"
                    width="90%"
                    alignSelf="center"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Telegram
                  </Box>
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  // target="_blank"
                  // href="https://twitter.com/busdstaker"
                >
                  <Box
                    mt={2}
                    zIndex={1}
                    style={{
                      cursor: "pointer",
                    }}
                    bgcolor="#28A07F"
                    width="90%"
                    alignSelf="center"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Twitter
                  </Box>
                </a> */}
              </Box>
              {/* Staker Dashboard end */}

              {/* deposit start */}
              <Box
                width="100%"
                pt={4}
                pb={4}
                pl={2}
                pr={2}
                borderRadius="10px"
                style={{
                  display: "flex",
                  boxShadow: "#BC9F4E 0px 5px 15px",
                  // justifyContent: "center",
                  flexDirection: "column",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
                mt={5}
                mb={5}
              >
                <Box
                  zIndex={1}
                  bgcolor="#1F1E1D"
                  width="95%"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#28A07F"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  mb={3}
                >
                  Deposit
                </Box>

                <Box borderRadius={5} mt={2}>
                  <Box
                    m={2}
                    // justifyContent="space-between"
                    display="flex"
                    flexDirection="row"
                    fontSize="20px"
                  >
                    <Box width="25%">ID</Box>
                    <Box width="25%">Amount</Box>
                    <Box width="25%">Withdrawn</Box>
                    <Box width="25%">Start Time</Box>
                  </Box>
                </Box>
                {deposit.length > 0 &&
                  deposit.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        m={2}
                        // justifyContent="space-between"
                        display="flex"
                        flexDirection="row"
                        fontSize="20px"
                        borderBottom=".025px solid white"
                      >
                        <Box width="25%">{++i}.</Box>
                        <Box width="25%">
                          {" "}
                          {parseFloat(item.amount).toFixed(0)}{" "}
                        </Box>
                        <Box width="25%">
                          {" "}
                          {parseFloat(item.withdrawn).toFixed(0)}{" "}
                        </Box>
                        <Box width="25%">
                          {" "}
                          {item?.startime &&
                            new Date(item.startime * 1000).toLocaleDateString(
                              "default"
                            )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              {/* deposit end */}
              {/* Affiliate Program start  */}

              <Box
                mt={4}
                py={2}
                width="100%"
                borderRadius="10px"
                style={{
                  display: "flex",

                  boxShadow: "#BC9F4E 0px 5px 15px",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
              >
                <Box style={{ width: "100%" }}>
                  <Box
                    m={2}
                    mt={5}
                    zIndex={1}
                    bgcolor="#1F1E1D"
                    width="95%"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#28A07F"
                    display="flex"
                    alignItems="center"
                    pl={1}
                    pr={1}
                    justifyContent="center"
                  >
                    <Box>Directs</Box>
                  </Box>
                </Box>
                {userDirects.length > 0 ? (
                  userDirects.map((address, index) => (
                    <Box
                      m={2}
                      width="90%"
                      justifyContent="space-between"
                      display="flex"
                      flexDirection="row"
                      fontSize="20px"
                    >
                      <Box>{index + 1}</Box>
                      <Box>
                        {address.slice(0, 4) + "..." + address.slice(-6)}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box fontSize="20px">You have no directs yet.</Box>
                )}
                <Box style={{ width: "100%" }}>
                  <Box
                    m={2}
                    mt={5}
                    zIndex={1}
                    bgcolor="#1F1E1D"
                    width="95%"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#28A07F"
                    display="flex"
                    alignItems="center"
                    pl={1}
                    pr={1}
                    justifyContent="center"
                  >
                    <Box display={"flex"} m={1}>
                      <PersonAddAlt1Icon />{" "}
                    </Box>
                    <Box>5x5 No Sponsoring Matrix</Box>
                  </Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>
                    1st Level (7%) : {userGetTotalRef1 ? userGetTotalRef1 : ""}{" "}
                    Stakers
                  </Box>
                  <Box> {getreward1 ? getreward1 : 0} BUSD</Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>
                    {" "}
                    2nd Level (3%) : {userGetTotalRef2
                      ? userGetTotalRef2
                      : ""}{" "}
                    Stakers
                  </Box>
                  <Box> {getreward2 ? getreward2 : 0} BUSD </Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>
                    3rd Level (3%) : {userGetTotalRef3 ? userGetTotalRef3 : ""}{" "}
                    Stakers{" "}
                  </Box>
                  <Box>{getreward3 ? getreward3 : 0} BUSD</Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>
                    4th Level (3%) : {userGetTotalRef4 ? userGetTotalRef4 : ""}{" "}
                    Stakers{" "}
                  </Box>
                  <Box>{getreward4 ? getreward4 : 0} BUSD</Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>
                    5th Level (7%) : {userGetTotalRef5 ? userGetTotalRef5 : ""}{" "}
                    Stakers{" "}
                  </Box>
                  <Box>{getreward5 ? getreward5 : 0} BUSD</Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Residual Bonus (3%):</Box>
                  <Box>{residualIncome ? residualIncome : 0} BUSD</Box>
                </Box>{" "}
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Available to Withdraw (Matrix Bonus):</Box>
                  <Box>{reffral_rewards ? reffral_rewards : 0} BUSD</Box>
                </Box>
                <Box
                  // mt={2}
                  zIndex={1}
                  style={{
                    cursor: +reffral_rewards > 0 ? "pointer" : "not-allowed",
                    // cursor: "pointer",
                  }}
                  bgcolor="#28A07F"
                  width="90%"
                  alignSelf="center"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  onClick={() => withdrawrefrewards()}
                >
                  <Box textAlign="center">Withdraw Matrix Rewards</Box>
                </Box>
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Matrix Income Withdrawn :</Box>
                  <Box>{getUserWithDraw ? getUserWithDraw : 0} BUSD</Box>
                </Box>{" "}
                <Box
                  m={2}
                  width="90%"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  fontSize="20px"
                >
                  <Box>Residual Bonus Withdrawn :</Box>
                  <Box>{residualWithdrawn ? residualWithdrawn : 0} BUSD</Box>
                </Box>
                {account ? (
                  <CopyToClipboard
                    text={
                      account
                        ? window.location.origin + "/busd_staker?ref=" + account
                        : "Please connect your wallet"
                    }
                    onCopy={() => {
                      if (account) {
                        toast.success("Ref Link Copied");
                      } else {
                        toast.error("Connect your wallet first");
                      }
                    }}
                  >
                    <Box
                      mt={2}
                      zIndex={1}
                      style={{
                        cursor: "pointer",
                      }}
                      bgcolor="#28A07F"
                      width="90%"
                      alignSelf="center"
                      height="45px"
                      fontWeight="bold"
                      borderRadius="8px"
                      fontSize="20px"
                      color="#ffffff"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pl={2}
                      pr={2}
                    >
                      <Box>Copy Affliate Link</Box>
                      <Box
                        style={{
                          color: "#ffffff",

                          alignItems: "center",
                          borderRadius: "8px",
                          width: "35px",
                          height: "35px",
                        }}
                      >
                        <ContentCopyIcon
                          style={{
                            width: "35px",
                            height: "35px",
                          }}
                        />
                      </Box>
                    </Box>
                  </CopyToClipboard>
                ) : (
                  <Box
                    my={2}
                    zIndex={1}
                    style={{
                      cursor: "pointer",
                    }}
                    bgcolor="#28A07F"
                    width="90%"
                    alignSelf="center"
                    height="45px"
                    fontWeight="bold"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pl={2}
                    pr={2}
                    onClick={connect}
                  >
                    <Box>Copy Affliate Link </Box>
                    <Box
                      style={{
                        color: "#ffffff",

                        alignItems: "center",
                        borderRadius: "8px",
                        width: "35px",
                        height: "35px",
                      }}
                    >
                      <ContentCopyIcon
                        style={{
                          width: "35px",
                          height: "35px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
                {/* <Box m={2} p={2} fontSize="20px">
                  Total Stakers Under Your Tree:{" "}
                  {getUsertreeCount ? getUsertreeCount : 0} Stakers
                  <br />
                  Total BUSD Deposits Under Your Tree:{" "}
                  {getUsertreeStake
                    ? formatUnits(getUsertreeStake.toString())
                    : 0}{" "}
                  BUSD
                </Box> */}
              </Box>

              {/* Affiliate Program end  */}

              {/* what is 100k Passive  */}
              <Box
                mt={4}
                width="100%"
                borderRadius="10px"
                style={{
                  display: "flex",

                  boxShadow: "#BC9F4E 0px 5px 15px",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
              >
                <Box
                  m={2}
                  mt={5}
                  zIndex={1}
                  bgcolor="#1F1E1D"
                  width="95%"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#28A07F"
                  display="flex"
                  alignItems="center"
                  pl={1}
                  pr={1}
                  justifyContent="center"
                >
                  <Box>What is 100k Passive</Box>
                </Box>
                <Box p={3} fontSize="20px">
                  100k Passive is a 100% decentralized Smart Contract
                  application built on the Binance Smart Chain and there are no
                  Developer interference as the contract cannot be altered or
                  changed once launched and funds are locked in the contract.
                  All movement of BUSD in the contract are distributed to its
                  participating Stakers exactly as written in the code. Proof of
                  third party revenue deposits can be verified by monitoring the
                  contract. Consistent deposits will be made from third party
                  revenue streams deposited into the contract from the support
                  wallet address 0xb6dB8eEe6eb7C060aeBe55b06b71505237f084EB
                </Box>
              </Box>
              {/* what is 100k Passive  */}

              <Box
                mt={4}
                width="100%"
                borderRadius="10px"
                style={{
                  display: "flex",
                  boxShadow: "#BC9F4E 0px 5px 15px",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(6px)",
                }}
                boxSizing="border-box"
                filter="blur(52px)"
                zIndex="10"
              >
                <Box
                  m={2}
                  mt={5}
                  zIndex={1}
                  bgcolor="#1F1E1D"
                  width="95%"
                  height="45px"
                  fontWeight="bold"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#28A07F"
                  display="flex"
                  alignItems="center"
                  pl={1}
                  pr={1}
                  justifyContent="center"
                >
                  <Box>HOW TO START STAKING</Box>
                </Box>
                <Box p={1} pb={4}>
                  <Accordion
                    style={{ background: "transparent" }}
                    onChange={handleChange("1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 28,
                          }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {" "}
                        <b>1. </b> Send BUSD to your
                        Trustwallet/Metamask/Safepal Wallet using
                        BSC/BEP20/Smart Chain Network address.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>1.1:</b> Also send a small amount of BNB to your
                        BSC/BEP20/Smart Chain Network address as you will use it
                        for gas fees. <br /> <b>1.2) </b> You can also buy BUSD
                        in pancakeswap by swapping out BNB or other BEP20
                        tokens.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <br />
                  <Accordion
                    style={{ background: "transparent" }}
                    onChange={handleChange("2")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 28,
                          }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {" "}
                        <b>2. </b>
                        Once crypto wallet is funded, go to its DApps and paste
                        the affiliate link of the person that invited you then
                        load the website.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>2.1:</b> Change the network icon to BSC/Smart Chain.{" "}
                        <br />
                        <b>2.2:</b> Connect Wallet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <br />
                  <Accordion
                    style={{ background: "transparent" }}
                    onChange={handleChange("3")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 28,
                          }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {" "}
                        <b>3. </b>
                        Type BUSD amount in the “box”, then click “Stake Your
                        BUSD” button.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>3.1: </b> Approve transactions and wait for it to be
                        approved/confirmed in the wallet transactions.
                        <br />
                        <b>3.2: </b> That’s it! you will now earn 0.714% daily
                        passive rewards until you receive 120% of your deposit!
                        <br />
                        <br />
                        Minimum Withdraw $100 Minimum Deposit $100 Maximum Daily
                        Withdraw $350.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Box display="flex" flexDirection="row" justifyContent="center">
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              style={{
                cursor: "pointer",
                color: "#ffffff",
                backgroundColor: "#28A07F",
                alignItems: "center",
                borderRadius: "8px",
                width: "45px",
                height: "45px",
              }}
            >
              <YouTubeIcon
                style={{
                  width: "45px",
                  height: "45px",
                }}
              />
            </Box>
            <a
              style={{ textDecoration: "none", cursor: "pointer" }}
              // target="_blank"
              // href="https://twitter.com/busdstaker"
            >
              <Box
                m={2}
                display="flex"
                justifyContent="center"
                style={{
                  cursor: "pointer",
                  color: "#ffffff",
                  backgroundColor: "#28A07F",
                  alignItems: "center",
                  borderRadius: "8px",
                  width: "45px",
                  height: "45px",
                }}
              >
                <TwitterIcon
                  style={{
                    width: "45px",
                    height: "45px",
                  }}
                />
              </Box>
            </a>
            <a
              style={{ textDecoration: "none", cursor: "pointer" }}
              // target="_blank"
              // href="https://t.me/busdstaker"
            >
              <Box
                m={2}
                display="flex"
                justifyContent="center"
                style={{
                  cursor: "pointer",
                  color: "#ffffff",
                  backgroundColor: "#28A07F",
                  alignItems: "center",
                  borderRadius: "8px",
                  width: "45px",
                  height: "45px",
                }}
              >
                <TelegramIcon
                  style={{
                    width: "45px",
                    height: "45px",
                  }}
                />
              </Box>
            </a>
          </Box> */}
        </Container>
      </Box>
    </>
  );
}
