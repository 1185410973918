import React, { useContext, useState, useEffect } from "react";
import Container from "@mui/material/Container";

import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import { AppContext, ChainId, seeder } from "../utils";

import logo from "../images/logo.png";
import Web3 from "web3";
import { Button, Stack } from "@mui/material";

const web3 = new Web3(Web3.givenProvider ? Web3.givenProvider : seeder);
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#1C0D38 !important",
    justifyContent: "center",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

export default function Header({ open, setOpen, web3 }) {
  // console.log("chainId", chainId);
  const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches = useMediaQuery("(max-width:960px)");
  const matches1 = useMediaQuery("(max-width:1279px)");
  const matches2 = useMediaQuery("(max-width:750px)");
  const ConnectNetwork = async () => {
    const chainid = await web3.eth.getChainId();

    if (+chainid !== 56) {
      setOpen(true);
    } else {
      connect();
    }
  };

  return (
    <>
      <Box
        position="fixed"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          background: matches2 ? "#040D4F" : "",
        }}
        height="92px"
        width="100%"
        zIndex={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={matches ? 0 : 5}
            pr={matches ? 0 : 5}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // flexBasis="20%"
            >
              <Box
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#000000",
                  fontSize: "20px",
                }}
              >
                <img
                  src="/logo.png"
                  width={matches2 ? "90px" : "90px"}
                  alt=""
                />
              </Box>
            </Box>
            <Stack direction="row" spacing={2}>
              {account ? (
                <Button
                  sx={{
                    bgcolor: "#28A07F",
                    boxShadow: "#BC9F4E 0px 0px 10px 3px",
                    fontWeight: "500",
                    borderRadius: "8px",
                    fontSize: { xs: "12px", sm: "20px" },
                    color: "#ffffff",
                  }}
                  onClick={() => disconnect()}
                >
                  {account.slice(0, 4) + "..." + account.slice(-4)}
                </Button>
              ) : (
                <Button
                  sx={{
                    bgcolor: "#28A07F",
                    boxShadow: "#BC9F4E 0px 0px 10px 3px",
                    fontWeight: "500",
                    borderRadius: "8px",
                    fontSize: { xs: "12px", sm: "20px" },
                    color: "#ffffff",
                  }}
                  onClick={connect}
                >
                  Connect
                </Button>
              )}
              <a
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  paddingRight: 15,
                }}
                target="_blank"
                href="https://swapspace.co/?ref=47630630041bea5ff04b383a"
              >
                <Button
                  sx={{
                    bgcolor: "#28A07F",
                    boxShadow: "#BC9F4E 0px 0px 10px 3px",
                    fontWeight: "500",
                    borderRadius: "8px",
                    fontSize: { xs: "12px", sm: "20px" },
                    color: "#ffffff",
                  }}
                >
                  Swap Crypto
                </Button>
              </a>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
}
