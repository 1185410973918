import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import NetworkChange from "./networkSwitch";

import { Box } from "@mui/system";
import bg from "./images/bg.jpg";
import { AppContext, seeder } from "./utils";

const web3 = new Web3(Web3.givenProvider ? Web3.givenProvider : seeder);

function App() {
  console.disableYellowBox = true;
  const { account } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [chainId, setchainId] = useState("");

  return (
    <Box
      className="glass-effect"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "100% 100%",
      }}
    >
      <NetworkChange open={open} setOpen={setOpen} />
      <Header open={open} setOpen={setOpen} chainId={chainId} web3={web3} />
      <Home open={open} setOpen={setOpen} chainId={chainId} />
    </Box>
  );
}

export default App;
